<template>
  <PxHeader :activeSearchMobile="false" />
  <section v-if="isBusy" class="containerGifLoading">
    <img src="@/assets/images/loaderagrogo.gif" class="sizeGifLoading" alt="" />
  </section>
  <section v-else class="bg-agrogo-green-2 sizeMinAllView">
    <!-- BOTON FLOTANTE PUBLICAR FINCA  -->
    <publish-button />
    <img
      class="hidden md:flex object-cover h-screen w-full"
      src="https://s3.us-east-2.amazonaws.com/boost.media.fincas.agrogo.com.co/pages/img/agro_go_1693950811_2pvt.jpeg"
      alt=""
    />
    <div class="container-bg-myaccount-desktop">
      <div class="bg-agrogo-green-2 bg-agrogo-green-2-transparent">
        <div class="container pt-6 pb-28 pl-7 pr-8 md:px-14 md:pt-14 md:pb-14">
          <div class="grid grid-cols-1 px-14 mb-8 md:px-0">
            <p
              class="font-nexa font-normal text-agrogo-yellow-1 text-base text-left md:font-bold md:text-4xl md:mb-2"
            >
              ¡Hola!
            </p>
            <p
              v-if="record.razon_social"
              class="font-nexa font-normal text-white text-lg text-center md:font-bold md:text-4xl md:text-left"
            >
              {{ record.razon_social }}
            </p>

            <p
              v-if="record.name && record.lastname"
              class="font-nexa font-normal text-white text-lg text-center md:font-bold md:text-4xl md:text-left"
            >
              {{ record.name }}
              {{ record.lastname }}
            </p>
          </div>
          <div class="grid grid-cols-1 place-content-center gap-2">
            <div class="flex justify-between items-center">
              <ul>
                <li
                  class="font-nexa font-normal text-white text-base focus:text-agrogo-yellow-1 cursor-pointer md:text-lg"
                >
                  <router-link class="flex" to="/mis-fincas"
                    ><span
                      class="icon-desplegar transform text-agrogo-yellow-1 mr-2 text-xs lg:mt-1"
                    ></span>
                    <span class="acount-text">Mis Fincas</span>
                  </router-link>
                </li>
              </ul>
            </div>
            <div class="border-t-2 border-agrogo-gray-2"></div>
            <div class="flex justify-between items-center">
              <ul>
                <li
                  class="font-nexa font-normal text-white text-base focus:text-agrogo-yellow-1 cursor-pointer md:text-lg"
                >
                  <router-link
                    class="flex"
                    to="/mi-cuenta-persona-juridica"
                    v-if="record.nit && record.razon_social"
                    ><span
                      class="icon-desplegar transform text-agrogo-yellow-1 mr-2 text-xs lg:mt-1"
                    ></span>
                    <span class="acount-text">Mi Perfil</span></router-link
                  >
                  <router-link
                    class="flex"
                    to="/mi-cuenta-persona-natural"
                    v-else-if="record.identy_document"
                    ><span
                      class="icon-desplegar transform text-agrogo-yellow-1 mr-2 text-xs lg:mt-1"
                    ></span>
                    <span class="acount-text">Mi Perfil</span></router-link
                  >
                  <p class="flex" @click="messageNoLogin()" v-else>
                    <span
                      class="icon-desplegar transform text-agrogo-yellow-1 mr-2 text-xs lg:mt-1"
                    ></span>
                    <span class="acount-text">Mi Perfil</span>
                  </p>
                </li>
              </ul>
            </div>
            <div class="border-t-2 border-agrogo-gray-2"></div>
            <div class="flex justify-between items-center">
              <ul @click.prevent="logout">
                <li
                  class="flex font-nexa font-normal text-white text-base focus:text-agrogo-yellow-1 cursor-pointer md:text-lg"
                >
                  <span
                    class="icon-desplegar transform text-agrogo-yellow-1 mr-2 text-xs lg:mt-1"
                  ></span>
                  <span class="acount-text">Cerrar sesión</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import PxHeader from "@/components/header/PxHeader";
import { mapActions, mapState } from "vuex";
import { generateHash } from "@/utils";
import validationToken from "@/mixins/validationToken";
import PublishButton from "@/components/publishButton/PublishButton";

export default {
  name: "MyAccount",
  inject: ["provider"],
  mixins: [validationToken],
  data() {
    const me = this;
    return {
      /**
       * Variable that enables or disables the loading gif
       * @type {Boolean}
       */
      isBusy: null,
      /**
       * Variable that saves the data of the logged in user
       * @type {Array}
       */
      record: [],
      /**
       * Variable that allows access to the methods of the provider myFarmRepository
       * @type {Object}
       */
      repository: me.provider.profileRepository,
      /**
       * Variable that allows access to the methods of the provider authRepository
       * @type {Object}
       */
      repositoryAuth: me.provider.authRepository,
    };
  },
  components: {
    PxHeader,
    PublishButton,
  },
  computed: {
    ...mapState("preLaunch", ["isPreLaunch"]),
  },
  methods: {
    ...mapActions("user", ["loadAuthenticated"]),
    ...mapActions("publishFarm", ["restoreData"]),
    /**
     * remove data from local storage related to login
     * @returns  {void}
     */
    async logout() {
      const me = this;
      await me.logoutApi();
      localStorage.removeItem("access_token");
      localStorage.removeItem("authenticated");
      sessionStorage.clear();
      me.loadAuthenticated(false);
      let path = me.isPreLaunch ? "/inicio-de-sesion" : "/inicio";
      me.$router.push(path);
    },
    /**
     * Execute the agrogó api and destroy the token of the logged in user.
     * @returns  {void}
     */
    async logoutApi() {
      const me = this;
      try {
        const dataAditional = await generateHash();
        await me.repositoryAuth.delete(dataAditional);
      } catch (error) {
        // console.log(error);
      } finally {
        me.$swal({
          iconHtml:
            '<svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M40.0005 6.66797L48.7555 13.0546L59.5938 13.0346L62.9222 23.348L71.7022 29.7013L68.3338 40.0013L71.7022 50.3013L62.9222 56.6546L59.5938 66.968L48.7555 66.948L40.0005 73.3346L31.2455 66.948L20.4072 66.968L17.0788 56.6546L8.29883 50.3013L11.6672 40.0013L8.29883 29.7013L17.0788 23.348L20.4072 13.0346L31.2455 13.0546L40.0005 6.66797Z" stroke="#F5CE3E" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path d="M28.334 40.0013L36.6673 48.3346L53.334 31.668" stroke="#F5CE3E" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/></svg>',
          customClass: {
            icon: "no-border-swal-alert",
          },
          text: "Su sesión ha sido cerrada correctamente",
          showConfirmButton: true,
          timer: 4000,
        });
      }
    },
    /**
     * Query the agrogo api and obtain the information of the logged in user.
     * @returns  {void}
     */
    async getProfile() {
      const me = this;
      try {
        me.isBusy = true;
        const dataAditional = await generateHash();
        const { data: data } = await me.repository.find(dataAditional);
        me.record = data.response;
      } catch (error) {
        // console.log(error);
        const code = error.response.status;
        if (code == 401) {
          me.validationExpiredToken();
        }
      } finally {
        me.isBusy = false;
      }
    },
    /**
     * This method executes an alert to inform the user.
     * @returns  {void}
     */
    messageNoLogin() {
      const me = this;
      me.$swal({
        iconHtml:
          '<svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M40.0005 6.66797L48.7555 13.0546L59.5938 13.0346L62.9222 23.348L71.7022 29.7013L68.3338 40.0013L71.7022 50.3013L62.9222 56.6546L59.5938 66.968L48.7555 66.948L40.0005 73.3346L31.2455 66.948L20.4072 66.968L17.0788 56.6546L8.29883 50.3013L11.6672 40.0013L8.29883 29.7013L17.0788 23.348L20.4072 13.0346L31.2455 13.0546L40.0005 6.66797Z" stroke="#F5CE3E" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path d="M28.334 40.0013L36.6673 48.3346L53.334 31.668" stroke="#F5CE3E" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/></svg>',
        customClass: {
          icon: "no-border-swal-alert",
        },
        text: "En el momento su sesión no está activa, por favor inicie sesión de nuevo.",
        showConfirmButton: true,
        timer: 8000,
      });
    },
  },
  mounted() {
    const me = this;
    window.scroll(0, 0);
    me.getProfile();
    me.restoreData();
  },
};
</script>
